import { useState, useContext, useEffect } from "react";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import {
  generateLastTwelveMonths,
  convertMonthLabelForApi,
  formatNumberFull,
  formatErrorForAlert,
  titleCase,
  sortTicketRanges,
  sortSubchannelsList,
  assembleBody,
  assembleBodyWithProductType,
  formatNumberRound,
  compareAndAddDummy,
  mergeData,
  padArray,
} from "../../services/UtilsService";
import LayoutPageContainer from "../../components/Layout/LayoutPageContainer";
import LayoutIssuersFilter from "../../components/Layout/LayoutIssuersFilter";
import LayoutIssuersSingleFilter from "../../components/Layout/LayoutIssuersSingleFilter";
import LayoutTwoColumns from "../../components/Layout/LayoutTwoColumns";
import TimePeriod from "../../components/TimePeriod/TimePeriod";
import FilterBoxSticky from "../../components/FilterBox/FilterBoxSticky";
import Refreshing from "../../components/Refreshing/Refreshing";
import Title from "../../components/Title/Title";
import SmallTitle from "../../components/Title/SmallTitle";
import SmallTitleFlex from "../../components/Title/SmallTitleFlex";
import KpiColumn from "../../components/Kpi/KpiColumn";
import McBarChartVertical from "../../components/McCharts/McBarChartVertical";
import McProgressChartBlock from "../../components/McCharts/McProgressChartBlock";
import McDonutChartSingle from "../../components/McCharts/McDonutChartSingle";
import McTreemapChart from "../../components/McCharts/McTreemapChart";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button/Button";
import TableAG from "../../components/Table/TableAG";
import McDonutSideLegend from "../../components/McCharts/McDonutSideLegend";
import Switcher from "../../components/Switcher/Switcher";
import Download from "../../components/Button/Download";

import {
  _variablesFour,
  _variablesTwelve,
  _variablesThreeOrangesRate,
  _variablesSix,
  _variablesTwoOranges,
} from "../../styles/_variables";
import LayoutCardFilter from "../../components/Layout/LayoutCardFilter";
import FilterBar from "../../components/FilterBox/FilterBar";

const colsDefMerchantAdviseCode = [
  { field: "label", headerName: "MAC", flex: 1 },
  { field: "value", headerName: "Transaction Share", flex: 1 },
];

const colDefs = [
  { field: "exemption", flex: 1 },
  {
    field: "transaction_share",
    headerName: "Transaction Share",
    flex: 1,
  },
  { field: "approval_rate", headerName: "Approval Rate", flex: 1 },
];

const AuthorizationIssuers = () => {
  const {
    merchantName,
    sidebarExpanded,
    acquirerName,
    isMultiAcquirer,
    timePeriod,
    setTimePeriod,
    userEmail,
  } = useContext(AccountContext);
  const [alert, setAlert] = useState({});
  const [dateLabels, setDateLabels] = useState([]);
  // acquirers
  const [isLoadingAcquirers, setIsLoadingAcquirers] = useState(true);
  const [acquirers, setAcquirers] = useState([]);
  const [selectedAcquirer, setSelectedAcquirer] = useState("");
  // issuers
  const [issuers, setIssuers] = useState([]);
  const [selectedIssuer, setSelectedIssuer] = useState();
  // top fifteen issuers
  const [isLoadingTopFifteen, setIsLoadingTopFifteen] = useState(false);
  const [dataTopFifteenTransactions, setDataTopFifteenTransactions] = useState(
    []
  );
  const [dataTopFifteenRate, setDataTopFifteenRate] = useState([]);
  // transactions kpi's
  const [isLoadingKpis, setIsLoadingKpis] = useState(false);
  const [dataKpis, setDataKpis] = useState([]);
  const [dataKpisAvg, setDataKpisAvg] = useState([]);
  const [dataKpisIssuers, setDataKpisIssuers] = useState([]);
  // approval rate by subchannel
  const [isLoadingBySubchannel, setIsLoadingBySubchannel] = useState(false);
  const [dataBySubchannel, setDataBySubchannel] = useState([]);
  const [dataBySubchannelIssuers, setDataBySubchannelIssuers] = useState([]);
  const [rateBySubchannel, setRateBySubchannel] = useState([]);
  const [rateBySubchannelIssuers, setRateBySubchannelIssuers] = useState([]);
  const [apiDataSubchannel, setApiDataSubchannel] = useState([]);
  const [apiDataSubchannelIssuers, setApiDataSubchannelIssuers] = useState([]);
  // ticket range
  const [isLoadingByTicketRange, setIsLoadingByTicketRange] = useState(true);
  const [dataByTicketRange, setDataByTicketRange] = useState([]);
  const [dataByTicketRangeIssuers, setDataByTicketRangeIssuers] = useState([]);
  const [rateByTicketRange, setRateByTicketRange] = useState([]);
  const [rateByTicketRangeIssuers, setRateByTicketRangeIssuers] = useState([]);
  const [apiDataTicketRange, setApiDataTicketRange] = useState([]);
  const [apiDataTicketRangeIssuers, setApiDataTicketRangeIssuers] = useState(
    []
  );
  const [mergedDataTicketRange, setMergedDataTicketRange] = useState([]);
  // token penetration
  const [isLoadingByTokenPenetration, setIsLoadingByTokenPenetration] =
    useState(true);
  const [dataByTokenPenetration, setDataByTokenPenetration] = useState([]);
  const [rateByTokenPenetration, setRateByTokenPenetration] = useState([]);
  const [dataByTokenPenetrationIssuers, setDataByTokenPenetrationIssuers] =
    useState([]);
  const [rateByTokenPenetrationIssuers, setRateByTokenPenetrationIssuers] =
    useState([]);
  // program
  const [isLoadingByProgram, setIsLoadingByProgram] = useState(true);
  const [dataByProgram, setDataByProgram] = useState([]);
  const [rateByProgram, setRateByProgram] = useState([]);
  const [dataByProgramIssuers, setDataByProgramIssuers] = useState([]);
  const [rateByProgramIssuers, setRateByProgramIssuers] = useState([]);
  // 3DS compliance
  const [data3DSCompliance, setData3DSCompliance] = useState([]);
  const [rate3DSCompliance, setRate3DSCompliance] = useState([]);
  const [isLoading3DSCompliance, setIsLoading3DSCompliance] = useState(true);
  const [data3DSComplianceIssuers, setData3DSComplianceIssuers] = useState([]);
  const [rate3DSComplianceIssuers, setRate3DSComplianceIssuers] = useState([]);
  const [apiData3DSCompliance, setApiData3DSCompliance] = useState([]);
  const [apiData3DSComplianceIssuers, setApiData3DSComplianceIssuers] =
    useState([]);
  const [mergedData3DSCompliance, setMergedData3DSCompliance] = useState([]);
  // exemption types
  const [dataExemptionTypes, setDataExemptionTypes] = useState([]);
  const [isLoadingExemptionTypes, setIsLoadingExemptionTypes] = useState(true);
  const [dataExemptionTypesIssuers, setDataExemptionTypesIssuers] = useState(
    []
  );
  // decline reasons
  const [isLoadingDeclineReasons, setIsLoadingDeclineReasons] = useState(true);
  const [dataDeclineReasons, setDataDeclineReasons] = useState([]);
  const [listDeclineReasons, setListDeclineReasons] = useState([]);
  const [dataDeclineReasonsIssuers, setDataDeclineReasonsIssuers] = useState(
    []
  );
  const [mergedDataDeclineReasons, setMergedDataDeclineReasons] = useState([]);
  // product type
  const [productType, setProductType] = useState("");
  // merchant advise code
  const [dataMerchantAdviseCodeDonut, setDataMerchantAdviseCodeDonut] =
    useState([]);
  const [
    isLoadingMerchantAdviseCodeDonut,
    setIsLoadingMerchantAdviseCodeDonut,
  ] = useState(true);
  const [dataMerchantAdviseCode, setDataMerchantAdviseCode] = useState([]);
  const [isLoadingMerchantAdviseCode, setIsLoadingMerchantAdviseCode] =
    useState(true);
  const [dataMerchantAdviseCodeIssuers, setDataMerchantAdviseCodeIssuers] =
    useState([]);
  const [
    dataMerchantAdviseCodeDonutIssuers,
    setDataMerchantAdviseCodeDonutIssuers,
  ] = useState([]);
  // transaction type
  const [transactionType, setTransactionType] = useState("count");

  // get dates for timeperiod
  useEffect(() => {
    let body = {
      merchant_name: merchantName,
    };
    FetchService.post("/api/dashboard/dates/autho", body)
      .then((res) => {
        setDateLabels(generateLastTwelveMonths(res.data.max_date_customer));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  }, []);
  const [mergedDataSubchannel, setMergedDataSubchannel] = useState([]);

  // get acquirers for selected timeperiod
  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
        let body = assembleBodyWithProductType(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          productType,
          undefined
        );
        getAcquirers(body);
        getTopFifteenIssuers(body);
        getKpis(body);
        getKpisAvg(body);
        getBySubchannel(body);
        getMerchantAdviseCode(body);
        getMerchantAdviseCodeDonut(body);
        getByTicketRange(body);
        getByTokenPenetration(body);
        getByProgram(body);
        get3DSCompliance(body);
        getExemptionTypes(body);
        getDeclineReasons(body);
        getKpis(body, true);
        getBySubchannel(body, true);
        getMerchantAdviseCode(body, true);
        getMerchantAdviseCodeDonut(body, true);
        getByTicketRange(body, true);
        getByTokenPenetration(body, true);
        getByProgram(body, true);
        get3DSCompliance(body, true);
        getExemptionTypes(body, true);
        getDeclineReasons(body, true);
      }
    }, 1500);
    return () => clearTimeout(timeOut);
  }, [dateLabels, timePeriod]);

  // get top 15 issuers for selected acquirer and issuers list
  useEffect(() => {
    isLoadingAll();
    if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
      let bodyIssuer = assembleBodyIssuer(
        merchantName,
        dateLabels[timePeriod[0]],
        dateLabels[timePeriod[1]],
        selectedIssuer,
        selectedAcquirer,
        productType,
        transactionType === "count" ? false : true
      );

      let bodyAcquirer = assembleBodyAcquirer(
        merchantName,
        dateLabels[timePeriod[0]],
        dateLabels[timePeriod[1]],
        selectedAcquirer,
        productType,
        transactionType === "count" ? false : true
      );

      getTopFifteenIssuers(bodyAcquirer);
      getKpis(bodyAcquirer);
      getKpisAvg(bodyAcquirer);
      getBySubchannel(bodyAcquirer);
      getMerchantAdviseCode(bodyAcquirer);
      getMerchantAdviseCodeDonut(bodyAcquirer);
      getByTicketRange(bodyAcquirer);
      getByTokenPenetration(bodyAcquirer);
      getByProgram(bodyAcquirer);
      get3DSCompliance(bodyAcquirer);
      getExemptionTypes(bodyAcquirer);
      getDeclineReasons(bodyAcquirer);
      getKpis(bodyIssuer, true);
      getBySubchannel(bodyIssuer, true);
      getMerchantAdviseCode(bodyIssuer, true);
      getMerchantAdviseCodeDonut(bodyIssuer, true);
      getByTicketRange(bodyIssuer, true);
      getByTokenPenetration(bodyIssuer, true);
      getByProgram(bodyIssuer, true);
      get3DSCompliance(bodyIssuer, true);
      getExemptionTypes(bodyIssuer, true);
      getDeclineReasons(bodyIssuer, true);
    }
  }, [selectedAcquirer, productType]);

  // get top 15
  useEffect(() => {
    if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
      let body = assembleBodyIssuer(
        merchantName,
        dateLabels[timePeriod[0]],
        dateLabels[timePeriod[1]],
        selectedAcquirer,
        productType,
        transactionType === "count" ? false : true
      );
      getTopFifteenIssuers(body);
      getKpisAvg(body);
    }
  }, [transactionType]);

  // get top 15 issuers for selected acquirer and issuers list
  useEffect(() => {
    if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
      let body = assembleBodyIssuer(
        merchantName,
        dateLabels[timePeriod[0]],
        dateLabels[timePeriod[1]],
        undefined,
        selectedAcquirer,
        productType,
        undefined
      );
      getKpis(body, true);
      getBySubchannel(body, true);
      getMerchantAdviseCode(body, true);
      getMerchantAdviseCodeDonut(body, true);
      getByTicketRange(body, true);
      getByTokenPenetration(body, true);
      getByProgram(body, true);
      get3DSCompliance(body, true);
      getExemptionTypes(body, true);
      getDeclineReasons(body, true);
    } else {
      clearChartsIssuer();
    }
  }, [selectedIssuer]);

  const getAcquirers = (body) => {
    setIsLoadingAcquirers(true);
    FetchService.post("api/dashboard/acquirers/autho", body)
      .then((res) => {
        let tempAcquirers = res.data.acquirers.sort((a, b) =>
          a.localeCompare(b)
        );
        tempAcquirers = tempAcquirers.map((el) => {
          return {
            value: el,
            text: el,
          };
        });

        tempAcquirers = [
          { value: "", text: "All Acquirers" },
          ...tempAcquirers,
        ];
        setAcquirers(tempAcquirers);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingAcquirers(false));
  };

  const getTopFifteenIssuers = (body) => {
    setIsLoadingTopFifteen(true);
    FetchService.post(
      "api/dashboard/autho/issuers/top-15-issuers-by-transactions",
      body
    )
      .then((res) => {
        let tempTransactions = [];
        let tempRate = [];
        let tempIssuers = [];

        res.data.forEach((el) => {
          tempTransactions.push({
            issuer: el.issuer_name ? titleCase(el.issuer_name) : "",
            value: el.sum_trx ? el.sum_trx / 1000 : 0,
          });
          tempRate.push({
            issuer: el.issuer_name ? titleCase(el.issuer_name) : "",
            value: el.auth_rate ? el.auth_rate : 0,
            avg: el.above_average ? el.above_average : 0,
          });
          tempIssuers.push({
            value: el.issuer_name ? el.issuer_name : "",
            text: el.issuer_name ? el.issuer_name : "",
          });
        });

        setDataTopFifteenTransactions(tempTransactions);
        setDataTopFifteenRate(tempRate);
        setIssuers([{ value: "", text: "All Issuers" }, ...tempIssuers]);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTopFifteen(false));
  };

  const getKpis = (body, isIssuer = false) => {
    setIsLoadingKpis(true);
    FetchService.post("/api/dashboard/autho/issuers/performance", body)
      .then((res) => {
        isIssuer ? setDataKpisIssuers(res.data) : setDataKpis(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingKpis(false));
  };

  const getKpisAvg = (body) => {
    setIsLoadingKpis(true);
    FetchService.post("/api/dashboard/autho/issuers/performance", body)
      .then((res) => {
        setDataKpisAvg(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingKpis(false));
  };

  const getBySubchannel = (body, isIssuer) => {
    setIsLoadingBySubchannel(true);

    FetchService.post(
      `/api/dashboard/autho/issuers/transactions-by-subchannel-and-approval-rate`,
      body
    )
      .then((res) => {
        isIssuer
          ? setApiDataSubchannelIssuers(res.data)
          : setApiDataSubchannel(res.data);
        let cleanRes = res.data.filter((el) => el.label !== "");
        cleanRes.sort((a, b) => a.label.localeCompare(b.label));

        let tempData = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.value ? el.value : 0,
          };
        });
        sortSubchannelsList(tempData);
        isIssuer
          ? setDataBySubchannelIssuers(tempData)
          : setDataBySubchannel(tempData);

        let tempRate = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.approval_rate ? el.approval_rate : 0,
          };
        });
        sortSubchannelsList(tempRate);
        isIssuer
          ? setRateBySubchannelIssuers(tempRate)
          : setRateBySubchannel(tempRate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingBySubchannel(false));
  };

  const getByTicketRange = (body, isIssuer) => {
    setIsLoadingByTicketRange(true);

    FetchService.post(
      `/api/dashboard/autho/issuers/transactions-by-ticket-range-and-approval-rate`,
      body
    )
      .then((res) => {
        isIssuer
          ? setApiDataTicketRangeIssuers(res.data)
          : setApiDataTicketRange(res.data);
        let tempData = res.data.map((el) => {
          return {
            name: el.label ? el.label + " €" : "",
            value: el.value ? el.value : 0,
          };
        });
        isIssuer
          ? setDataByTicketRangeIssuers(sortTicketRanges(tempData, "€"))
          : setDataByTicketRange(sortTicketRanges(tempData, "€"));

        let tempRate = res.data.map((el) => {
          return {
            name: el.label ? el.label + " €" : "",
            value: el.approval_rate ? el.approval_rate : 0,
          };
        });
        isIssuer
          ? setRateByTicketRangeIssuers(sortTicketRanges(tempRate, "€"))
          : setRateByTicketRange(sortTicketRanges(tempRate, "€"));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByTicketRange(false));
  };

  const getByTokenPenetration = (body, isIssuer) => {
    setIsLoadingByTokenPenetration(true);

    FetchService.post(
      `/api/dashboard/autho/issuers/transactions-by-token-penetration-and-approval-rate`,
      body
    )
      .then((res) => {
        let cleanRes = res.data.sort((a, b) =>
          a.label < b.label ? 1 : a.label > b.label ? -1 : 0
        );

        let tempData = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.value ? el.value : 0,
          };
        });
        isIssuer
          ? setDataByTokenPenetrationIssuers(tempData)
          : setDataByTokenPenetration(tempData);

        let tempRate = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.approval_rate ? el.approval_rate : 0,
          };
        });
        isIssuer
          ? setRateByTokenPenetrationIssuers(tempRate)
          : setRateByTokenPenetration(tempRate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByTokenPenetration(false));
  };

  const getByProgram = (body, isIssuer) => {
    setIsLoadingByProgram(true);

    FetchService.post(
      `/api/dashboard/autho/issuers/transactions-by-program-and-approval-rate`,
      body
    )
      .then((res) => {
        let cleanRes = res.data.sort((a, b) =>
          a.label > b.label ? 1 : a.label < b.label ? -1 : 0
        );

        let tempData = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.value ? el.value : 0,
          };
        });
        isIssuer
          ? setDataByProgramIssuers(tempData)
          : setDataByProgram(tempData);

        let tempRate = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.approval_rate ? el.approval_rate : 0,
          };
        });
        isIssuer
          ? setRateByProgramIssuers(tempRate)
          : setRateByProgram(tempRate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByProgram(false));
  };

  const get3DSCompliance = (body, isIssuer) => {
    setIsLoading3DSCompliance(true);
    FetchService.post("/api/dashboard/autho/issuers/3DS-compliance", body)
      .then((res) => {
        isIssuer
          ? setApiData3DSComplianceIssuers(res.data)
          : setApiData3DSCompliance(res.data);
        let cleanRes = res.data.sort((a, b) =>
          a.label > b.label ? 1 : a.label < b.label ? -1 : 0
        );
        let tempData = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.trx_share ? el.trx_share : 0,
          };
        });
        let tempRate = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.approval_rate ? el.approval_rate : 0,
          };
        });

        isIssuer
          ? setData3DSComplianceIssuers(tempData)
          : setData3DSCompliance(tempData);

        isIssuer
          ? setRate3DSComplianceIssuers(tempRate)
          : setRate3DSCompliance(tempRate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoading3DSCompliance(false));
  };

  const getExemptionTypes = (body, isIssuer) => {
    setIsLoadingExemptionTypes(true);
    FetchService.post("/api/dashboard/autho/issuers/exemption-types", body)
      .then((res) => {
        let rowData = res.data.map((el) => {
          return {
            exemption: el.label ? el.label : "",
            transaction_share:
              formatNumberRound(Number(el.trx_share ? el.trx_share : 0)) + "%",
            approval_rate:
              formatNumberRound(
                Number(el.approval_rate ? el.approval_rate : 0)
              ) + "%",
          };
        });
        isIssuer
          ? setDataExemptionTypesIssuers(rowData)
          : setDataExemptionTypes(rowData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingExemptionTypes(false));
  };

  const getDeclineReasons = (body, isIssuer) => {
    setIsLoadingDeclineReasons(true);

    FetchService.post(`/api/dashboard/autho/issuers/decline-reasons`, body)
      .then((res) => {
        let tempData = [];
        res.data.forEach((el) => {
          tempData.push({
            name: el.label ? el.label : "Unknown",
            size: el.value ? el.value : 0,
            percent: formatNumberFull(el.percent ? el.percent : 0),
          });
        });

        let tempReasons = listDeclineReasons;
        res.data.forEach((reason) => {
          if (!tempReasons.includes(reason.label)) {
            tempReasons.push(reason.label);
          }
        });
        setListDeclineReasons(tempReasons);

        isIssuer
          ? setDataDeclineReasonsIssuers(tempData)
          : setDataDeclineReasons(tempData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingDeclineReasons(false));
  };

  // donut data
  const getMerchantAdviseCodeDonut = (body, isIssuer) => {
    setIsLoadingMerchantAdviseCodeDonut(true);
    FetchService.post(
      "/api/dashboard/autho/issuers/merchant-advice-code-total-percentage",
      body
    )
      .then((res) => {
        let data = [];
        if (Object.keys(res.data).length !== 0) {
          data.push(
            {
              name: "Mac Present",
              value: res.data.mac_present ? res.data.mac_present : 0,
            },
            {
              name: "Mac Absent",
              value: res.data.mac_absent ? res.data.mac_absent : 0,
            }
          );
        }
        isIssuer
          ? setDataMerchantAdviseCodeDonutIssuers(data)
          : setDataMerchantAdviseCodeDonut(data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingMerchantAdviseCodeDonut(false));
  };

  // table data
  const getMerchantAdviseCode = (body, isIssuer) => {
    setIsLoadingMerchantAdviseCode(true);
    FetchService.post("/api/dashboard/autho/issuers/merchant-advice-code", body)
      .then((res) => {
        let tempData = [];
        res.data.map((el) => {
          tempData.push({
            label: el.label ? el.label : "",
            value: formatNumberRound(el.percent ? el.percent : 0) + "%",
          });
        });

        isIssuer
          ? setDataMerchantAdviseCodeIssuers(tempData)
          : setDataMerchantAdviseCode(tempData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingMerchantAdviseCode(false));
  };

  const assembleBodyIssuer = () => {
    return {
      merchant_name: merchantName,
      start_date: convertMonthLabelForApi(dateLabels[timePeriod[0]]),
      end_date: convertMonthLabelForApi(dateLabels[timePeriod[1]]),
      issuer_name: selectedIssuer,
      acquirer_name: selectedAcquirer,
      product_type: productType,
      is_volume: transactionType === "count" ? false : true,
    };
  };

  const assembleBodyAcquirer = () => {
    return {
      merchant_name: merchantName,
      start_date: convertMonthLabelForApi(dateLabels[timePeriod[0]]),
      end_date: convertMonthLabelForApi(dateLabels[timePeriod[1]]),
      acquirer_name: selectedAcquirer,
      product_type: productType,
      is_volume: transactionType === "count" ? false : true,
    };
  };

  const isLoadingAll = () => {
    setIsLoadingTopFifteen(true);
    setIsLoadingKpis(true);
    setIsLoadingBySubchannel(true);
    setIsLoadingMerchantAdviseCode(true);
    setIsLoadingMerchantAdviseCodeDonut(true);
    setIsLoadingByTicketRange(true);
    setIsLoadingByTokenPenetration(true);
    setIsLoadingByProgram(true);
    setIsLoading3DSCompliance(true);
    setIsLoadingExemptionTypes(true);
    setIsLoadingDeclineReasons(true);
  };

  const clearChartsIssuer = () => {
    setDataKpisIssuers([]);
    setRateBySubchannelIssuers([]);
    setDataMerchantAdviseCodeIssuers([]);
    setDataMerchantAdviseCodeDonutIssuers([]);
    setDataByTicketRangeIssuers([]);
    setDataByTokenPenetrationIssuers([]);
    setDataByProgramIssuers([]);
    setData3DSComplianceIssuers([]);
    setDataExemptionTypesIssuers([]);
    setDataDeclineReasonsIssuers([]);
  };

  const handleSelectAcquirer = (e) => {
    console.log("E", e);
    setSelectedAcquirer(e);
    handleSelectIssuer("");
  };

  const handleSelectIssuer = (e) => {
    setSelectedIssuer(e);
  };

  const handleSelectedProductType = (e) => {
    setProductType(e);
  };

  let pages = [
    {
      title: "Overview",
      link: "/authorization/home",
    },
    { title: "Geography", link: "/authorization/geography" },
    { title: "Benchmark", link: "/authorization/benchmark" },
    { title: "Acquirers", link: "/authorization/acquirers" },
    { title: "Issuers", link: "/authorization/issuers", active: true },
  ];

  if (!isMultiAcquirer && !acquirerName) {
    pages = pages.filter((el) => el.title !== "Acquirers");
  }

  function mergeTopFifteen(array1, array2) {
    return array1.map((item1) => {
      const item2 = array2.find((item2) => item2.issuer === item1.issuer);
      return item2
        ? {
            ...item1,
            authRate: item2.value,
          }
        : item1;
    });
  }

  function compareAndAddDummy(
    array1,
    array2,
    label,
    param1 = null,
    param2 = null
  ) {
    const param1Name = param1 ? param1 : "trx_share";
    const param2Name = param2 ? param2 : "approval_rate";
    const dummyValue = { [param1Name]: 0.0, [param2Name]: 0.0 };
    const labelSet1 = new Set(array1.map((item) => item[label]));
    const result = [];

    array2.forEach((item) => {
      if (labelSet1.has(item[label])) {
        result.push(array1.find((x) => x[label] === item[label]));
      } else {
        result.push({ [label]: item[label], ...dummyValue });
      }
    });

    return result;
  }

  useEffect(() => {
    setMergedDataSubchannel(
      mergeData(
        apiDataSubchannel,
        compareAndAddDummy(
          apiDataSubchannelIssuers,
          apiDataSubchannel,
          "label",
          "value",
          "approval_rate"
        ),
        [
          { source: "value", target: "trx_share_acquirer" },
          { source: "approval_rate", target: "approval_rate_acquirer" },
        ],
        [
          { source: "value", target: "trx_share_issuer" },
          { source: "approval_rate", target: "approval_rate_Issuer" },
        ],
        "label"
      )
    );
  }, [apiDataSubchannel, apiDataSubchannelIssuers]);

  useEffect(() => {
    setMergedDataTicketRange(
      mergeData(
        apiDataTicketRange,
        compareAndAddDummy(
          apiDataTicketRangeIssuers,
          apiDataTicketRange,
          "label"
        ),
        [
          { source: "value", target: "value_acquirer" },
          { source: "approval_rate", target: "approval_rate_acquirer" },
        ],
        [
          { source: "value", target: "value_issuer" },
          { source: "approval_rate", target: "approval_rate_issuer" },
        ],
        "label"
      )
    );
  }, [apiDataTicketRange, apiDataTicketRangeIssuers]);

  useEffect(() => {
    setMergedData3DSCompliance(
      mergeData(
        apiData3DSCompliance,
        compareAndAddDummy(
          apiData3DSComplianceIssuers,
          apiData3DSCompliance,
          "label",
          "trx_share",
          "approval_rate"
        ),
        [
          { source: "trx_share", target: "trx_share_acquirer" },
          { source: "approval_rate", target: "approval_rate_acquirer" },
        ],
        [
          { source: "trx_share", target: "trx_share_issuer" },
          { source: "approval_rate", target: "approval_rate_issuer" },
        ],
        "label"
      )
    );
  }, [apiData3DSCompliance, apiData3DSComplianceIssuers]);

  useEffect(() => {
    setMergedDataDeclineReasons(
      mergeData(
        dataDeclineReasons,
        compareAndAddDummy(
          dataDeclineReasonsIssuers,
          dataDeclineReasons,
          "name",
          "percent"
        ),
        [{ source: "percent", target: "percent_acquirer" }],
        [{ source: "percent", target: "percent_issuer" }],
        "name"
      )
    );
  }, [dataDeclineReasons, dataDeclineReasonsIssuers]);

  return (
    <LayoutPageContainer
      title={"Authorization"}
      pages={pages}
      alert={alert}
      setAlert={setAlert}
    >
      <Refreshing
        conditions={[
          isLoadingAcquirers,
          isLoadingTopFifteen,
          isLoadingKpis,
          isLoadingBySubchannel,
          isLoadingMerchantAdviseCode,
          isLoadingMerchantAdviseCodeDonut,
          isLoadingByTicketRange,
          isLoadingByTokenPenetration,
          isLoadingByProgram,
          isLoading3DSCompliance,
          isLoadingExemptionTypes,
          isLoadingDeclineReasons,
        ]}
        marginLeft={sidebarExpanded}
      />
      <TimePeriod
        dateLabels={dateLabels}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
      />

      {/* first filter */}
      <FilterBar>
        <LayoutCardFilter
          onChange={handleSelectedProductType}
          tooltipAcquirer=""
          options={[
            { value: "", text: "All products" },
            { value: "CR", text: "Credit" },
            { value: "DB", text: "Debit" },
            /*             { value: "PP", text: "Prepaid" },
             */
          ]}
        />
        {isMultiAcquirer && (
          <LayoutIssuersSingleFilter
            options={acquirers}
            tooltipAcquirer={""}
            onChange={handleSelectAcquirer}
            hideNames={userEmail === "demo@mastercard.it"}
          />
        )}
      </FilterBar>

      <hr />
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "2rem",
        }}
      >
        <Switcher
          pages={[
            {
              title: "Transactions",
              onClick: () => {
                setTransactionType("count");
              },
            },
            {
              title: "Volumes",
              onClick: () => {
                setTransactionType("volume");
              },
            },
          ]}
        />
      </div> */}
      <div className="d-flex justify-content-between">
        <SmallTitleFlex
          title={"Top 15 issuers by transactions"}
          tooltip={
            "Top 15 Issuers listed in descending order by number of transactions."
          }
        />
        <Download
          section="AuthoIssuers"
          chartName="Top 15 issuers by transactions"
          data={mergeTopFifteen(dataTopFifteenTransactions, dataTopFifteenRate)}
          graphType="barChart"
          productType={productType}
          timeRange={
            dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
          }
          acquirer={selectedAcquirer}
          label={"Issuer"}
          field1={transactionType === "count" ? "Transactions" : "Volumes"}
          value1={"value"}
          field2={"Approval Rate (%)"}
          value2={"authRate"}
          iconSize="sm"
          className="h-8 w-8"
        />
      </div>
      <Switcher
        pages={[
          {
            title: "Transactions",
            onClick: () => {
              setTransactionType("count");
            },
          },
          {
            title: "Volumes",
            onClick: () => {
              setTransactionType("volume");
            },
          },
        ]}
      />
      <LayoutTwoColumns
        leftContent={
          <>
            <div className="text-center mb-3 fw-semibold ">
              {transactionType === "count"
                ? "Attempted Transactions (K)"
                : "Attempted Volumes (K€)"}
            </div>
            <McBarChartVertical
              background={false}
              data={dataTopFifteenTransactions}
              dataGuide={{
                y: "issuer",
                barsData: [{ dataName: "value", friendlyName: "Transactions" }],
              }}
              tooltipMeasureUnit=" K"
              hideNamesIssuers={userEmail === "demo@mastercard.it"}
            />
          </>
        }
        rightContent={
          <>
            <div className="text-center mb-3">Approval rate (%)</div>
            <McBarChartVertical
              data={dataTopFifteenRate}
              dataGuide={{
                y: "issuer",
                barsData: [{ dataName: "value", friendlyName: "Rate" }],
              }}
              labels={["Rate (%)"]}
              legend
              tooltipMeasureUnit="%"
              avg={dataKpisAvg.approval_rate}
              hideNamesIssuers={userEmail === "demo@mastercard.it"}
            />
          </>
        }
      />

      <FilterBoxSticky>
        <LayoutIssuersFilter
          acquirer={selectedAcquirer}
          tooltipMerchant={
            "Merchant performance includes the overall performances.<br /><br />" +
            "Approval rate is calculated as: number of approved transactions divided by total transactions.<br /><br />" +
            "Average ticket is calculated as: volumes (spend) divided by transactions."
          }
          tooltipIssuer={
            "Issuer performance indicates the performances of selected Issuer.<br /><br />" +
            "Approval rate is calculated as: number of approved transactions divided by total transactions.<br /><br />" +
            "Average ticket is calculated as: volumes (spend) divided by transactions."
          }
          options={issuers}
          onChange={handleSelectIssuer}
          hideNames={userEmail === "demo@mastercard.it"}
        />
      </FilterBoxSticky>

      <LayoutTwoColumns
        split
        leftContent={
          <>
            <Title styleClass="d-block d-lg-none mb-3">
              Overall performance
            </Title>
            <KpiColumn
              kpis={[
                {
                  leftContent:
                    (dataKpis.transaction_approved
                      ? formatNumberFull(dataKpis.transaction_approved / 1000)
                      : "0") + " K",
                  righContent: "Transactions approved",
                  color: _variablesTwoOranges.primaryOrange,
                },
                {
                  leftContent:
                    (dataKpis.transaction_denied
                      ? formatNumberFull(dataKpis.transaction_denied / 1000)
                      : "0") + " K",
                  righContent: "Transactions declined",
                  color: _variablesTwoOranges.primaryOrangeLight,
                },
              ]}
            />

            <hr />
            <div className="mb-4">
              <SmallTitleFlex
                title={"Transactions by subchannel"}
                tooltip={
                  "Card not present transactions split by subchannel incidence: Recurring, Ecommerce, MOTO or Other.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />
              <McDonutChartSingle
                data={dataBySubchannel}
                side={"legend"}
                palette={_variablesFour}
                tooltipMeasureUnit="%"
              />
            </div>
            <div className="mb-4">
              <SmallTitleFlex
                title="Approval rate by subchannel"
                tooltip={
                  "Approval rates by different subchannels: Ecommerce, MOTO, Recurring, Others Approval rate is defined as number of successfully approved transactions to total transactions"
                }
              ></SmallTitleFlex>
              <McProgressChartBlock
                data={padArray(rateBySubchannel, rateBySubchannelIssuers)}
                palette={_variablesFour}
              />
            </div>
            <hr style={{ marginBottom: "5.6rem" }} />

            <div className="mb-4">
              <SmallTitleFlex
                title={"Transaction count by ticket range"}
                tooltip={
                  "Card not present transactions split by ticket range incidence: 0€, 0.01-30€, 30.01-100€, 100.01-250€, 250.01-500€, or +500€.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />
              <McDonutChartSingle
                data={dataByTicketRange}
                side={"legend"}
                palette={_variablesSix}
                tooltipMeasureUnit="%"
              />
            </div>

            <SmallTitle>Approval rate by ticket range</SmallTitle>
            <McProgressChartBlock
              data={padArray(rateByTicketRange, rateByTicketRangeIssuers)}
              palette={_variablesSix}
            />

            <hr />

            <div className="mb-4">
              <SmallTitleFlex
                title={"Transactions by token penetration"}
                tooltip={
                  "Card not present transactions split by tokenization incidence: tokenized or not tokenized.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />
              <McDonutChartSingle
                data={dataByTokenPenetration}
                side={"legend"}
                palette={_variablesTwoOranges}
                tooltipMeasureUnit="%"
              />
            </div>
            <SmallTitle>Approval rate by token penetration</SmallTitle>
            <McProgressChartBlock
              data={padArray(
                rateByTokenPenetration,
                rateByTokenPenetrationIssuers
              )}
              palette={_variablesTwoOranges}
            />
            <hr />
            <div className="mb-4">
              <SmallTitleFlex
                title={"Transactions by program"}
                tooltip={
                  "Card not present transactions split by program incidence: 3DS or non 3DS.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />
              <McDonutChartSingle
                data={dataByProgram}
                side={"legend"}
                palette={_variablesTwoOranges}
                tooltipMeasureUnit="%"
              />
            </div>

            <SmallTitle>Approval rate by program</SmallTitle>
            <McProgressChartBlock
              data={padArray(rateByProgram, rateByProgramIssuers)}
              palette={_variablesTwoOranges}
            />

            <hr />
            <div className="mb-4">
              <>
                <SmallTitleFlex
                  title={"3DS Exemptions Share"}
                  tooltip={
                    "Transaction share of various CNP compositions along with exemptions."
                  }
                />
                <div
                  style={{ display: "flex" }}
                  className="flex-lg-column flex-xl-row"
                >
                  <McDonutChartSingle
                    data={data3DSCompliance}
                    palette={_variablesSix}
                    tooltipMeasureUnit="%"
                  />
                  <div
                    style={{
                      marginTop: "",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <b>Transaction Share</b>
                    <McDonutSideLegend
                      data={data3DSCompliance}
                      palette={_variablesSix}
                    />
                  </div>
                </div>
                <Modal
                  dataBsTarget="exemptionsModal"
                  title={<b>Non-3DS Exemption Types</b>}
                  modalBody={
                    <div
                      style={{
                        width: "100%",
                        height: "30rem",
                        display: "flex",
                      }}
                    >
                      <TableAG colDefs={colDefs} rowData={dataExemptionTypes} />
                    </div>
                  }
                >
                  <Download
                    section="AuthoAcquirers"
                    chartName="Non-3DS Exemption Types"
                    data={dataExemptionTypes}
                    graphType="pie"
                    label={"Exemption Type"}
                    productType={productType}
                    timeRange={
                      dateLabels[timePeriod[0]] +
                      " - " +
                      dateLabels[timePeriod[1]]
                    }
                    fields={[
                      {
                        field: "Transaction Share",
                        value: "transaction_share",
                      },
                      { field: "Approval Rate", value: "approval_rate" },
                    ]}
                    iconSize="sm"
                    className="h-8 w-8"
                  />
                </Modal>
              </>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <SmallTitleFlex
                title="Approval Rate by 3DS exemptions"
                tooltip={
                  "Approval rate of various CNP compositions along with exemptions.<br /><br />" +
                  "Approval rate is defined as number of successfully approved transactions over total transactions"
                }
              />
              <McProgressChartBlock
                data={rate3DSCompliance}
                palette={_variablesSix}
              />
              <Button
                text="Exemptions Details"
                style={{
                  fontSize: "12pt",
                  padding: "0.5rem",
                  marginTop: "2rem",
                  width: "15rem",
                  //marginLeft:"6rem",
                }}
                dataBsToggle="modal"
                dataBsTarget={"exemptionsModal"}
              />
            </div>
            <hr />

            <div className="mb-4">
              <>
                <SmallTitleFlex
                  title={"Transaction share by merchant advice code (MAC)"}
                  tooltip={
                    "Share of declined transactions split by MAC Present or Absent status"
                  }
                />
                <div
                  style={{ display: "flex" }}
                  className="flex-lg-column flex-xl-row"
                >
                  <McDonutChartSingle
                    data={dataMerchantAdviseCodeDonut}
                    palette={_variablesTwoOranges}
                    tooltipMeasureUnit="%"
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <span>
                      <b>Transaction Share </b>
                      (within declined transactions)
                    </span>
                    <McDonutSideLegend
                      data={dataMerchantAdviseCodeDonut.map((el) => {
                        return { name: el.name, value: el.value };
                      })}
                      palette={_variablesTwoOranges}
                    />
                  </div>
                </div>
                <Modal
                  dataBsTarget="authMethodModal"
                  title={<b>Deepdive of MAC transaction share</b>}
                  modalBody={
                    <div
                      style={{
                        width: "100%",
                        height: "30rem",
                        display: "flex",
                      }}
                    >
                      <TableAG
                        colDefs={colsDefMerchantAdviseCode}
                        rowData={dataMerchantAdviseCode}
                      />
                    </div>
                  }
                >
                  <Download
                    section="AuthoIssuers"
                    chartName="MAC"
                    data={dataMerchantAdviseCode}
                    graphType="pie"
                    label={"MAC"}
                    acquirer={selectedAcquirer}
                    issuer={selectedIssuer}
                    timeRange={
                      dateLabels[timePeriod[0]] +
                      " - " +
                      dateLabels[timePeriod[1]]
                    }
                    fields={[{ field: "Transaction Share", value: "value" }]}
                    iconSize="sm"
                    className="h-8 w-8"
                  />
                </Modal>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <Button
                    text="Details"
                    style={{
                      fontSize: "12pt",
                      padding: "0.5rem",
                      marginTop: "3rem",
                      width: "15rem",
                      // marginLeft: "auto",
                    }}
                    dataBsToggle="modal"
                    dataBsTarget={"authMethodModal"}
                  />
                </div>
              </>
            </div>
            <hr />
            <SmallTitleFlex
              title={"Decline reasons"}
              tooltip={
                "Incidence of each decline reason on total number of declined transactions (card not present channel only). " +
                "Area of each rectangle indicates the magnitude of each decline reason."
              }
            />
            <McTreemapChart
              data={dataDeclineReasons}
              listDeclineReasons={listDeclineReasons}
            />
          </>
        }
        rightContent={
          <>
            <Title styleClass="d-block d-lg-none mb-3">
              Issuer performance
            </Title>
            <KpiColumn
              kpis={[
                {
                  leftContent:
                    (dataKpisIssuers.transaction_approved
                      ? formatNumberFull(
                          dataKpisIssuers.transaction_approved / 1000
                        )
                      : "0") + " K",
                  righContent: "Transactions approved",
                  color: _variablesTwoOranges.primaryOrange,
                },
                {
                  leftContent:
                    (dataKpisIssuers.transaction_denied
                      ? formatNumberFull(
                          dataKpisIssuers.transaction_denied / 1000
                        )
                      : "0") + " K",
                  righContent: "Transactions declined",
                  color: _variablesTwoOranges.primaryOrangeLight,
                },
              ]}
            />

            <hr />

            <div className="mb-4">
              <div className="d-flex justify-content-between">
                <SmallTitleFlex
                  title={"Transactions by subchannel"}
                  tooltip={
                    "Card not present transactions split by subchannel incidence: Recurring, Ecommerce, MOTO or Other.<br /><br />" +
                    "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                  }
                />
                <Download
                  section="AuthoIssuers"
                  chartName="Subchannel"
                  data={mergedDataSubchannel}
                  graphType="pie"
                  productType={productType}
                  acquirer={selectedAcquirer}
                  issuer={selectedIssuer}
                  timeRange={
                    dateLabels[timePeriod[0]] +
                    " - " +
                    dateLabels[timePeriod[1]]
                  }
                  label={"Subchannel"}
                  fields={[
                    {
                      field: "Acquirer Share (%)",
                      value: "trx_share_acquirer",
                    },
                    {
                      field: "Issuer Share (%)",
                      value: "trx_share_issuer",
                    },
                    {
                      field: "Acquirer Approval Rate (%)",
                      value: "approval_rate_acquirer",
                    },
                    {
                      field: "Issuer Approval Rate (%)",
                      value: "approval_rate_issuer",
                    },
                  ]}
                  iconSize="sm"
                  className="h-8 w-8"
                />
              </div>
              <McDonutChartSingle
                data={dataBySubchannelIssuers}
                side={"legend"}
                palette={_variablesFour}
                tooltipMeasureUnit="%"
              />
            </div>
            <div className="mb-4">
              <SmallTitleFlex
                title="Approval rate by subchannel"
                tooltip={
                  "Approval rates by different subchannels: Ecommerce, MOTO, Recurring, Others Approval rate is defined as number of successfully approved transactions to total transactions"
                }
              />
              <McProgressChartBlock
                data={padArray(rateBySubchannelIssuers, rateBySubchannel)}
                palette={_variablesFour}
              />
            </div>
            <hr style={{ marginBottom: "2rem" }} />

            <div
              className="d-flex justify-content-end"
              style={{ marginBottom: "1rem" }}
            >
              <Download
                section="AuthoAcquirers"
                chartName="TicketRange"
                productType={productType}
                acquirer={selectedAcquirer}
                issuer={selectedIssuer}
                data={mergedDataTicketRange}
                graphType="pie"
                label={"Ticket Range"}
                timeRange={
                  dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
                }
                fields={[
                  { field: "Acquirer Share (%)", value: "value_acquirer" },
                  { field: "Issuer Share (%)", value: "value_issuer" },
                  {
                    field: "Acquirer Approval Rate (%)",
                    value: "approval_rate_acquirer",
                  },
                  {
                    field: "Issuer Approval Rate (%)",
                    value: "approval_rate_issuer",
                  },
                ]}
                iconSize="sm"
                className="h-8 w-8"
              />
            </div>

            <div className="mb-4">
              <SmallTitleFlex
                title={"Transaction count by ticket range"}
                tooltip={
                  "Card not present transactions split by ticket range incidence: 0€, 0.01-30€, 30.01-100€, 100.01-250€, 250.01-500€, or +500€.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />
              <McDonutChartSingle
                data={dataByTicketRangeIssuers}
                side={"legend"}
                palette={_variablesSix}
                tooltipMeasureUnit="%"
              />
            </div>

            <SmallTitle>Approval rate by ticket range</SmallTitle>
            <McProgressChartBlock
              data={padArray(rateByTicketRangeIssuers, rateByTicketRange)}
              palette={_variablesSix}
            />

            <hr />

            <div className="mb-4">
              <SmallTitleFlex
                title={"Transactions by token penetration"}
                tooltip={
                  "Card not present transactions split by tokenization incidence: tokenized or not tokenized.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />
              <McDonutChartSingle
                data={dataByTokenPenetrationIssuers}
                side={"legend"}
                palette={_variablesTwoOranges}
                tooltipMeasureUnit="%"
              />
            </div>

            <SmallTitle>Approval rate by token penetration</SmallTitle>
            <McProgressChartBlock
              data={padArray(
                rateByTokenPenetrationIssuers,
                rateByTokenPenetration
              )}
              palette={_variablesTwoOranges}
            />

            <hr />

            <div className="mb-4">
              <SmallTitleFlex
                title={"Transactions by program"}
                tooltip={
                  "Card not present transactions split by program incidence: 3DS or non 3DS.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />
              <McDonutChartSingle
                data={dataByProgramIssuers}
                side={"legend"}
                palette={_variablesTwoOranges}
                tooltipMeasureUnit="%"
              />
            </div>

            <SmallTitle>Approval rate by program</SmallTitle>
            <McProgressChartBlock
              data={padArray(rateByProgramIssuers, rateByProgram)}
              palette={_variablesTwoOranges}
            />

            <hr />
            <div className="mb-4">
              <>
                <div className="d-flex justify-content-between">
                  <SmallTitleFlex
                    title={"3DS Exemptions Share"}
                    tooltip={
                      "Transaction share of various CNP compositions along with exemptions."
                    }
                  />
                  <Download
                    section="AuthoIssuers"
                    chartName="3DS Exemptions"
                    data={mergedData3DSCompliance}
                    graphType="pie"
                    label={"3DS Exemptions"}
                    productType={productType}
                    acquirer={selectedAcquirer}
                    issuer={selectedIssuer}
                    timeRange={
                      dateLabels[timePeriod[0]] +
                      " - " +
                      dateLabels[timePeriod[1]]
                    }
                    fields={[
                      {
                        field: "Acquirer Share (%)",
                        value: "trx_share_acquirer",
                      },
                      {
                        field: "Issuer Share (%)",
                        value: "trx_share_issuer",
                      },
                      {
                        field: "Acquirer Approval Rate (%)",
                        value: "approval_rate_acquirer",
                      },
                      {
                        field: "Issuer Approval Rate (%)",
                        value: "approval_rate_issuer",
                      },
                    ]}
                    iconSize="sm"
                    className="h-8 w-8"
                  />
                </div>
                <div
                  style={{ display: "flex" }}
                  className="flex-lg-column flex-xl-row"
                >
                  <McDonutChartSingle
                    data={data3DSComplianceIssuers}
                    palette={_variablesSix}
                    tooltipMeasureUnit="%"
                  />
                  <div
                    style={{
                      marginTop: "",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    {data3DSComplianceIssuers.length > 0 && (
                      <>
                        <b>Transaction Share</b>
                        <McDonutSideLegend
                          data={compareAndAddDummy(
                            data3DSComplianceIssuers,
                            data3DSCompliance,
                            "name",
                            "value"
                          )}
                          palette={_variablesSix}
                        />
                      </>
                    )}
                  </div>
                </div>
                <Modal
                  dataBsTarget="exemptionsModalIssuers"
                  title={<b>Non-3DS Exemption Types</b>}
                  modalBody={
                    <div
                      style={{
                        width: "100%",
                        height: "30rem",
                        display: "flex",
                      }}
                    >
                      <TableAG
                        colDefs={colDefs}
                        rowData={dataExemptionTypesIssuers}
                      />
                    </div>
                  }
                >
                  <Download
                    section="AuthoIssuers"
                    chartName="Non-3DS Exemption Types"
                    data={dataExemptionTypesIssuers}
                    graphType="pie"
                    productType={productType}
                    acquirer={selectedAcquirer}
                    issuer={selectedIssuer}
                    label={"Exemption Type"}
                    timeRange={
                      dateLabels[timePeriod[0]] +
                      " - " +
                      dateLabels[timePeriod[1]]
                    }
                    fields={[
                      {
                        field: "Transaction Share",
                        value: "transaction_share",
                      },
                      { field: "Approval Rate", value: "approval_rate" },
                    ]}
                    iconSize="sm"
                    className="h-8 w-8"
                  />
                </Modal>
              </>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <SmallTitleFlex
                title="Approval Rate by 3DS exemptions"
                tooltip={
                  "Approval rate of various CNP compositions along with exemptions.<br /><br />" +
                  "Approval rate is defined as number of successfully approved transactions over total transactions"
                }
              />
              <McProgressChartBlock
                data={
                  selectedAcquirer !== null
                    ? padArray(
                        compareAndAddDummy(
                          rate3DSComplianceIssuers,
                          rate3DSCompliance,
                          "name",
                          "value"
                        ),
                        rate3DSCompliance
                      )
                    : padArray(rate3DSComplianceIssuers, rate3DSCompliance)
                }
                palette={_variablesSix}
              />
              {dataExemptionTypesIssuers.length > 0 ? (
                <Button
                  text="Exemptions Details"
                  style={{
                    fontSize: "12pt",
                    padding: "0.5rem",
                    marginTop: "2rem",
                    width: "15rem",
                    //marginLeft:"6rem",
                  }}
                  dataBsToggle="modal"
                  dataBsTarget={"exemptionsModalIssuers"}
                />
              ) : (
                <div
                  style={{
                    fontSize: "12pt",
                    padding: "0.5rem",
                    marginTop: "2rem",
                    width: "15rem",
                    height: "2.6rem",
                  }}
                ></div>
              )}
            </div>

            <hr />

            <div className="mb-4">
              <>
                <SmallTitleFlex
                  title={"Transaction share by merchant advice code (MAC)"}
                  tooltip={
                    "Share of declined transactions split by MAC Present or Absent status"
                  }
                />
                <div
                  style={{ display: "flex" }}
                  className="flex-lg-column flex-xl-row"
                >
                  <McDonutChartSingle
                    data={dataMerchantAdviseCodeDonutIssuers}
                    palette={_variablesTwoOranges}
                    tooltipMeasureUnit="%"
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    {dataMerchantAdviseCodeDonutIssuers.length > 0 && (
                      <span>
                        <b>Transaction Share </b>
                        (within declined transactions)
                      </span>
                    )}
                    <McDonutSideLegend
                      data={dataMerchantAdviseCodeDonutIssuers.map((el) => {
                        return { name: el.name, value: el.value };
                      })}
                      palette={_variablesTwoOranges}
                    />
                  </div>
                </div>
                <Modal
                  dataBsTarget="authMethodModalIssuers"
                  title={<b>Deepdive of MAC transaction share</b>}
                  modalBody={
                    <div
                      style={{
                        width: "100%",
                        height: "30rem",
                        display: "flex",
                      }}
                    >
                      <TableAG
                        colDefs={colsDefMerchantAdviseCode}
                        rowData={dataMerchantAdviseCodeIssuers}
                      />
                    </div>
                  }
                >
                  <Download
                    section="AuthoIssuers"
                    chartName="MAC"
                    data={dataMerchantAdviseCodeIssuers}
                    graphType="pie"
                    label={"MAC"}
                    acquirer={selectedAcquirer}
                    issuer={selectedIssuer}
                    timeRange={
                      dateLabels[timePeriod[0]] +
                      " - " +
                      dateLabels[timePeriod[1]]
                    }
                    fields={[{ field: "Transaction Share", value: "value" }]}
                    iconSize="sm"
                    className="h-8 w-8"
                  />
                </Modal>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  {dataMerchantAdviseCodeDonutIssuers.length > 0 ? (
                    <Button
                      text="Details"
                      style={{
                        fontSize: "12pt",
                        padding: "0.5rem",
                        marginTop: "3rem",
                        width: "15rem",
                      }}
                      dataBsToggle="modal"
                      dataBsTarget={"authMethodModalIssuers"}
                    />
                  ) : (
                    <div
                      style={{
                        fontSize: "12pt",
                        padding: "0.5rem",
                        marginTop: "3rem",
                        width: "15rem",
                        height: "2.6rem",
                      }}
                    ></div>
                  )}
                </div>
              </>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <SmallTitleFlex
                title={"Decline reasons"}
                tooltip={
                  "Incidence of each decline reason on total number of declined transactions (card not present channel only). " +
                  "Area of each rectangle indicates the magnitude of each decline reason."
                }
              />
              <Download
                section="AuthoIssuers"
                chartName="FailureReasons"
                data={mergedDataDeclineReasons}
                graphType="decline"
                productType={productType}
                acquirer={selectedAcquirer}
                issuer={selectedIssuer}
                label={"Decline Reason"}
                timeRange={
                  dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
                }
                fields={[
                  {
                    field: "Percentage Acquirer (%)",
                    value: "percent_acquirer",
                  },
                  { field: "Percentage Issuer (%)", value: "percent_issuer" },
                ]}
                iconSize="sm"
                className="h-8 w-8"
              />
            </div>
            <McTreemapChart
              data={dataDeclineReasonsIssuers}
              listDeclineReasons={listDeclineReasons}
            />
          </>
        }
      />
    </LayoutPageContainer>
  );
};

export default AuthorizationIssuers;
