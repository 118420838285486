import Select from "../Select/Select";
import StatefulSelect from "../Select/StatefulSelect";
import TooltipInfo from "../TooltipInfo/TooltipInfo";
import LayoutTwoColumns from "./LayoutTwoColumns";

const LayoutIssuersFilter = ({
  options,
  tooltipMerchant,
  tooltipIssuer,
  onChange,
  acquirer,
  hideNames = false,
}) => {
  // If hideNames is true, replace the names with "Issuer1", "Issuer2", etc.
  let issuerIndex = 1;
  const customOptions = hideNames
    ? options.map((option) => {
        let newText = option.text;
        if (!option.text.includes("All") && !option.text.includes("Others")) {
          newText = `Issuer ${issuerIndex}`;
          issuerIndex++;
        }
        return {
          ...option,
          text: newText,
        };
      })
    : options;

  return (
    <LayoutTwoColumns
      leftContent={
        <div className="justify-content-center align-items-end h-100 d-none d-lg-flex">
          <div className="me-2">
            {acquirer?.length > 0 ? <b>Acquirer</b> : <b>Merchant</b>}
          </div>
          <TooltipInfo content={tooltipMerchant} />
        </div>
      }
      rightContent={
        <>
          <div className="mb-lg-3">
            <StatefulSelect
              options={customOptions}
              onChange={onChange}
              placeholder="All Issuers"
              styleClass="w-100"
              key={acquirer}
            />
          </div>
          <div className="justify-content-center align-items-center d-none d-lg-flex">
            <div className="me-2">
              <b>Issuer</b>
            </div>
            <TooltipInfo content={tooltipIssuer} />
          </div>
        </>
      }
      type={"acquirer"}
    />
  );
};

export default LayoutIssuersFilter;
